import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import useTheme from "../../state/hooks/useTheme";
import { getTheme, InlineField } from "@grafana/ui";
import styled from "styled-components";
import { style } from "../../styles";
import { ReactComponent as Arrow } from '../../assets/images/arrow_select.svg'
import Botao from "../Botao";
import useToast from "../../state/hooks/useToast";

interface DateFilterProps {
    onFilter: (from: string, to: string) => void;
    label?: string;
}

const Container = styled.div<{ theme: any }>`
    width: auto;
    height: auto;

    & label {
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
        font-family: ${style.font.family.light};
    }
`

const ContainerInput = styled.div<{ theme: any }>`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        background-color: #cdcdcd;
        cursor: pointer;
        border-radius: 50%;
        padding: .3rem;
    }

    & label, & input, & input::placeholder {
        font-family: ${style.font.family.light};
    }

    & label {
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
        font-size: 14px;
        align-self: start;
    }

    & input {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.border2};
        color: ${({ theme }) => theme.colors.text};
        transition: all .2s;
        font-size: 14px;
        padding: .2rem .5rem;

        &:hover {
            border: 1px solid ${({ theme }) => theme.colors.border3};
        }
    }

    & input::placeholder {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text};
    }
`

const ContainerSelect = styled.div<{ themeGrafana: any, open: boolean }>`
    width: 300px;
    height: 32px;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 .5rem 0 1rem;
    font-family: ${style.font.family.light};
    position: relative;
    border: 1px solid ${({ themeGrafana }) => themeGrafana.colors.border2};
    color: ${({ themeGrafana }) => themeGrafana.colors.text};
    box-shadow: ${({ open, themeGrafana }) => open ? `0px 0px 0px 4px ${themeGrafana.colors.formInputBorderActive}` : ''};
    outline: ${({ open }) => open ? `2px solid black` : 'none'};
    transition: all .3s;
    
    & p {
        font-size: 14px;
    }

    &:hover {
        border: 1px solid ${({ themeGrafana }) => themeGrafana.colors.border3};
    }

    &:hover > div {
        color: ${({ themeGrafana }) => themeGrafana.colors.border3}
    }

`

const IconSelect = styled.div<{ theme: any, open: boolean }>`
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.border2};
    transform: ${({ open }) => open ? "rotate(180deg)" : ""};
    transition: all .6s;
`

const MenuSelect = styled.div<{ theme: any, open: boolean }>`
    width: 220px;
    height: 200px;
    left: 0px;
    top: calc(100% + 10px);
    left: calc(100% - 220px);
    box-sizing: border-box;
    position: absolute;
    border-radius: 2px;
    padding: .5rem;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${({ theme }) => theme.name === 'Light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};
    box-shadow: 0px 3px 15px 1px ${({ theme }) => theme.name === 'Light' ? 'rgba(0,0,0,0.4)' : 'rgb(0,0,0)'};
    border: 1px solid ${({ theme }) => theme.colors.border2};
        
    svg {
        transform: rotate(180deg);
    }
`

const DateFilter: React.FC<DateFilterProps> = ({ onFilter, label }) => {
    const { theme } = useTheme();
    const themeGrafana = getTheme(theme);

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("Selecione um range de data");
    const { addToast } = useToast();

    const handleApply = () => {
        if (!startDate || !endDate) {
            addToast('Por favor, preencha ambas as datas', 'critical');
            return;
        }

        const from = dayjs(startDate).format("DD/MM/YYYY HH:mm");
        const to = dayjs(endDate).format("DD/MM/YYYY HH:mm");

        setValue(`${from} - ${to}`);

        const fromTimestamp = dayjs(startDate).valueOf().toString();
        const toTimestamp = dayjs(endDate).valueOf().toString();

        onFilter(fromTimestamp, toTimestamp);
        setOpen(false);
    };

    const menuRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    return (
        <Container ref={menuRef} theme={themeGrafana}>
            <InlineField
                label='Filtro por data'
            >
                <ContainerSelect open={open} themeGrafana={themeGrafana} onClick={() => setOpen(!open)}>
                    <p>{label ? label : value}</p>
                    <IconSelect theme={themeGrafana} open={open}>
                        <Arrow width={16} height={16} />
                    </IconSelect>
                    {
                        open ?
                            <MenuSelect theme={themeGrafana} open={open}>
                                <ContainerInput theme={themeGrafana}>
                                    <label htmlFor="start-date">Data de Início:</label>
                                    <input
                                        type="datetime-local"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </ContainerInput>
                                <ContainerInput theme={themeGrafana}>
                                    <label htmlFor="end-date">Data de Fim:</label>
                                    <input
                                        type="datetime-local"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </ContainerInput>

                                <Botao label="Aplicar Filtro" width="70%" variant="info" onClick={handleApply} />
                            </MenuSelect> : null
                    }
                </ContainerSelect>
            </InlineField>
        </Container>
    );
};

export default DateFilter;