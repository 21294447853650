import React, { useState, useRef, useEffect } from "react";
import Page from "..";
import styled, { css, keyframes } from "styled-components";
import useTheme from '../../state/hooks/useTheme';
import { style } from "../../styles";
import { TTheme } from "../../types/types";
import Input from "../../components/Input";
import { getTheme } from "@grafana/ui";
import { ReactComponent as IconDownload } from '../../assets/images/download.svg'
import Alert from "../../components/Alert";
import instancia from "../../instancia";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import useToast from "../../state/hooks/useToast";
import Avatar from "../../components/Avatar";
import Botao from "../../components/Botao";
import { ReactComponent as LoadingIcon } from '../../assets/images/load.svg';
import { handleSuportPortal } from "../../utils";

const Container = styled.div<{ theme: TTheme }>`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    overflow: auto;
    overflow-x: hidden;
    margin-top: 2rem;
    background-color: ${props => props.theme === 'light' ? style.colors.light["bg-secondary"] : style.colors.dark["bg-secondary"]};
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    box-sizing: border-box;
    padding: 2rem 3rem;
    border-radius: .5rem;
`;

const BoxDados = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
`;

const BoxFoto = styled.div<{ theme: any }>`
    width: 15%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-left: 2px solid ${props => props.theme.name === 'Light' ? style.colors.light["bg-primary"] : style.colors.dark["bg-primary"]};

    & button {
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
        border: 1px solid ${({ theme }) => theme.colors.border1};
        font-family: ${style.font.family.light};

        &:hover {
            background-color: ${({ theme }) => theme.colors.bg1};
            border: 1px solid ${({ theme }) => theme.colors.border2};
            color: ${({ theme }) => theme.colors.text};
            cursor: pointer;
        }
    }
`;

const ContainerDados = styled.div<{ theme: TTheme }>`
    min-width: 20%;
    margin-bottom: 2rem;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const LabelDados = styled.div`
    width: 100%;
    height: auto;
    font-family: ${style.font.family.light};
    font-size: ${style.font.size.p};
    align-text: start;
`;

const ValueDados = styled.div`
    width: 100%;
    height: auto;
    font-family: ${style.font.family.medium};
    font-size: ${style.font.size.p};
    align-text: start;
`;

const DetailsContrato = styled.details`
    width: 100%;
    padding: 1rem;
    font-family: ${style.font.family.medium};
    border: 1px solid black;
    border-radius: 4px;
    margin: 1rem 0px;
    
`

const SummaryContrato = styled.summary<{ theme: any }>`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & button {
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.bg1};
        color: ${({ theme }) => theme.colors.text};
        border: 1px solid ${({ theme }) => theme.colors.border1};
        font-family: ${style.font.family.light};

        &:hover {
            background-color: ${({ theme }) => theme.colors.bg1};
            border: 1px solid ${({ theme }) => theme.colors.border2};
            color: ${({ theme }) => theme.colors.text};
            cursor: pointer;
        }
    }
`

const Usuarios = styled.div`
    border-top: 1px solid black;
    margin-top: 2rem;
    padding-top: 1rem;
`

const ItemUsuarios = styled.div<{ theme: any }>`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;

    &:first-child:hover {
        background-color: transparent;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.bg2};
    }
`

const ContainerBotoes = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(LoadingIcon) <{ isLoading: boolean }>`
    width: 18px;
    height: 18px;
    ${({ isLoading }) => isLoading && css`
        animation: ${spin} 1s linear infinite;
    `}
`;

const Conteudo = () => {
    const { theme } = useTheme();
    const themeGrafana = getTheme(theme);
    const { addToast } = useToast();

    const [senha, setSenha] = useState(false);
    const [refreshSenha, setRefreshSenha] = useState(false);
    const [status, setStatus] = useState(false);

    const [emailUser, setEmailUser] = useState("");
    const [typeStatus, setTypeStatus] = useState("");

    const [senhaAtual, setSenhaAtual] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [confirmSenha, setConfirmSenha] = useState("");

    const email = localStorage.getItem('email');

    const [contratos, setContratos] = useState<any[]>([]);

    const [loadStatus, setLoadStatus] = useState(false);
    const [loadUpdatePassword, setLoadUpdatePassword] = useState(false);
    const [loadReset, setLoadReset] = useState(false);
    const [acessoNegado, setAcessoNegado] = useState(false);

    const updatePassword = async () => {
        setLoadUpdatePassword(true);
        try {
            if (confirmSenha === novaSenha) {
                const request = await instancia.post(`/contratos/update-password?code=${process.env.REACT_APP_API_CODE}`,
                    { currentPassword: senhaAtual, newPassword: novaSenha },
                    { withCredentials: true });


                if (request.status === 200) {
                    setLoadUpdatePassword(false);
                    addToast("Senha atualizada com sucesso!", 'success')

                } else {
                    setLoadUpdatePassword(false);
                    addToast('Erro ao atualizar senha', 'critical')
                }
            } else {
                setLoadUpdatePassword(false);
                addToast('Confirmação de senha diferente da nova senha', 'warning')
            }

        } catch (err) {
            setLoadUpdatePassword(false);
            addToast('Erro ao atualizar senha', 'critical')
        }
    }

    const statusUser = async () => {
        setLoadStatus(true);
        try {
            const request = await instancia.post(`contratos/change-status?code=${process.env.REACT_APP_API_CODE}`,
                { userEmail: emailUser, status: typeStatus === 'desativar' ? false : true },
                { withCredentials: true });

            if (request.status === 200) {
                setLoadStatus(false);
                addToast("Status atualizado com sucesso!", 'success')
            } else {
                setLoadStatus(false);
                addToast("Erro ao atualizar status", 'critical')
            }
        } catch (err) {
            setLoadStatus(false);
            addToast("Erro ao atualizar status", 'critical')
        }
    }

    const refreshPassword = async () => {
        setLoadReset(true);
        try {
            const request = await instancia.post(`contratos/reset-password?code=${process.env.REACT_APP_API_CODE}`,
                { userEmail: emailUser },
                { withCredentials: true });

            if (request.status === 200) {
                setLoadReset(false);
                addToast("Senha redefinida com sucesso!", 'success')
            } else {
                setLoadReset(false);
                addToast("Erro ao redefinir senha!", 'critical')
            }
        } catch (err) {
            setLoadReset(false);
            addToast("Erro ao redefinir senha!", 'critical')
        }
    }

    useEffect(() => {
        instancia
            .get(`contratos?code=${process.env.REACT_APP_API_CODE}`, { withCredentials: true })
            .then(res => {
                setContratos(res.data?.contratos);
            })
            .catch(err => {
                setAcessoNegado(true)
            });
    }, [])

    const handleDownload = (base64Pdf: string, servico: string) => {
        const byteCharacters = atob(base64Pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `contrato_${servico}_cafs.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container theme={theme}>
            {senha ? (
                <Modal closeModal={() => setSenha(false)}>
                    <>
                        <div style={{ width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Input label="Senha atual" placeholder="" type="password" onChange={(e) => setSenhaAtual(e.target.value)} />
                            <Input label="Nova senha" placeholder="" type="password" onChange={(e) => setNovaSenha(e.target.value)} />
                            <Input label="Confirme nova senha" placeholder="" type="password" onChange={(e) => setConfirmSenha(e.target.value)} />
                        </div>
                        <ContainerBotoes>
                            <Botao disabled={loadUpdatePassword} icon={loadUpdatePassword ? <RotatingIcon isLoading={loadUpdatePassword} /> : undefined} label="Trocar senha" variant="info" width="100%" onClick={updatePassword} />
                        </ContainerBotoes>
                    </>
                </Modal>
            ) : null}
            {
                refreshSenha ? (
                    <Modal closeModal={() => setRefreshSenha(false)}>
                        <>
                            <Alert
                                severidade="warning"
                                titulo="Redefinição de senha"
                                texto={`Tem certeza que deseja redefinir a senha do seguinte usuário ${emailUser}`}
                            />
                            <ContainerBotoes>
                                <Botao label="Sim" disabled={loadReset} icon={loadReset ? <RotatingIcon isLoading={loadReset} /> : undefined} variant="success" width="49%" onClick={refreshPassword} />
                                <Botao label="Não" variant="critical" width="49%" onClick={() => setRefreshSenha(false)} />
                            </ContainerBotoes>
                        </>
                    </Modal>
                ) : null
            }
            {
                status ? (
                    <Modal closeModal={() => setStatus(false)}>
                        <>
                            <Alert
                                severidade="warning"
                                titulo={`${typeStatus === 'desativar' ? 'Desativação' : 'Ativação'} de usuário`}
                                texto={`Tem certeza que deseja ${typeStatus} o seguinte usuário ${emailUser}`}
                            />
                            <ContainerBotoes>
                                <Botao label="Sim" disabled={loadStatus} icon={loadStatus ? <RotatingIcon isLoading={loadStatus} /> : undefined} variant="success" width="49%" onClick={statusUser} />
                                <Botao label="Não" variant="critical" width="49%" onClick={() => setStatus(false)} />
                            </ContainerBotoes>
                        </>
                    </Modal>

                ) : null
            }
            <BoxDados>
                <div style={{ width: '100%', height: '20%', marginBottom: '5%' }}>
                    <ContainerDados theme={theme}>
                        <LabelDados>Email</LabelDados>
                        <ValueDados>{email || ""}</ValueDados>
                    </ContainerDados>
                </div>
                <div id="contratos" style={{ width: '100%', height: '90%', marginTop: '1rem' }}>
                    {
                        contratos?.length !== 0 ?
                        <>
                            <LabelDados>Contratos</LabelDados>
                            <>
                                {
                                    contratos.map((value) => (
                                        <DetailsContrato>
                                            <SummaryContrato theme={themeGrafana}>
                                                {value.servico} | {value.empresa}
                                                <Botao
                                                    icon={<IconDownload width={16} height={16} />}
                                                    variant="secondary"
                                                    onClick={() => handleDownload(value.pdf, value.servico)}
                                                    label="Baixar contrato"
                                                    width="150px"
                                                />
                                            </SummaryContrato>
                                            <Usuarios>
                                                <ItemUsuarios theme={themeGrafana}>
                                                    <p style={{ width: '33%' }}>Nome</p>
                                                    <p style={{ width: '33%' }}>Email</p>
                                                    <p style={{ width: '225px' }}>Funções</p>
                                                </ItemUsuarios>
                                                {
                                                    value.usuarios.map((usuario: any) => (
                                                        <ItemUsuarios theme={themeGrafana}>
                                                            <p style={{ width: '33%' }}>{usuario.nome}</p>
                                                            <p style={{ width: '33%' }}>{usuario.email}</p>
                                                            <div style={{ width: '225px', display: 'flex', justifyContent: 'space-between' }}>
                                                                <Botao
                                                                    variant="warning"
                                                                    onClick={() => {
                                                                        setEmailUser(usuario.email);
                                                                        setRefreshSenha(true);
                                                                    }}
                                                                    label="Redefinir senha"
                                                                    width="120px"
                                                                />
                                                                <Botao
                                                                    variant={usuario.ativo ? 'success' : 'critical'}
                                                                    label={usuario.ativo ? "Ativo" : "Desativado"}
                                                                    onClick={() => {
                                                                        setEmailUser(usuario.email);
                                                                        setTypeStatus(usuario.ativo ? "desativar" : "ativar");
                                                                        setStatus(true);
                                                                    }}
                                                                    width="100px"
                                                                />
                                                            </div>
                                                        </ItemUsuarios>
                                                    ))
                                                }
                                            </Usuarios>
                                        </DetailsContrato>
                                    ))
                                }
                            </></> : acessoNegado ? null
                            : <Loading />
                    }
                </div>
            </BoxDados>
            <BoxFoto theme={themeGrafana}>
                <Avatar size={70} email={email || ""} />
                <Botao
                    label="Trocar senha"
                    variant="secondary"
                    width="150px"
                    onClick={() => setSenha(true)}
                />
                <Botao
                    label="Suporte"
                    variant="secondary"
                    width="150px"
                    onClick={handleSuportPortal}
                />
            </BoxFoto>
        </Container>
    );
};

const PagePerfil = () => {
    return (
        <Page conteudo={({ open }) => <Conteudo />} />
    );
};

export default PagePerfil;
