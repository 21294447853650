import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { style } from "../../styles";
import useTheme from '../../state/hooks/useTheme';
import { TTheme } from "../../types/types";
import { ReactComponent as Perfil } from '../../assets/images/perfil.svg';
import { ReactComponent as PerfilColorido } from '../../assets/images/perfil-colorido.svg';
import { ReactComponent as Suporte } from '../../assets/images/suporte.svg';
import { ReactComponent as SuporteColorido } from '../../assets/images/suporte-colorido.svg';
import { ReactComponent as Sair } from '../../assets/images/sair.svg';
import { ReactComponent as SairColorido } from '../../assets/images/sair-colorido.svg';
import { Link, To, useNavigate } from "react-router-dom";
import Dropdown from "../Dropdown";
import Avatar from "../Avatar";
import instancia from "../../instancia";
import Botao from "../Botao";
import { handleSuportPortal } from "../../utils";

const ContainerItem = styled(Link) <{ theme: TTheme, open: boolean }>`
    width: 100%;
    height: 34px;
    box-sizing: border-box;
    padding: .3125rem .625rem;
    background-color: ${props => props.theme === 'light' ? style.colors.light['bg-secondary'] : style.colors.dark["bg-secondary"]};
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.open ? 'start' : 'center'};
    color: ${props => props.theme === 'light' ? style.colors.light["font-color"] : style.colors.dark["font-color"]};
    transition: all .3s ease;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: ${props => props.theme === 'light' ? style.colors.light['bg-primary'] : style.colors.dark["bg-primary"]};
    }
`;

const Icone = styled.div`
    width: 34px;
    height: 34px;
    margin-right: .3125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
        transition: fill .6s ease;
    }
`;

const Label = styled.p`
    font-size: 14px;
    font-family: ${style.font.family.light};
`;

interface IItemMenu {
    icone: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    iconeColodiro: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string | boolean;
    link: To;
    open: boolean;
}

const ItemMenu = ({ icone: IconSvg, iconeColodiro: IconSvgColorido, label, link, open }: IItemMenu) => {
    const [icon, setIcon] = useState(<IconSvg />);
    const { theme } = useTheme();

    return (
        <ContainerItem
            to={link}
            theme={theme}
            open={open}
            onMouseLeave={() => setIcon(<IconSvg />)}
            onMouseEnter={() => setIcon(<IconSvgColorido />)}
        >
            <Icone>
                {icon}
            </Icone>
            {label ? <Label>{label}</Label> : null}
        </ContainerItem>
    );
};

const MenuAvatar = () => {
    const email = localStorage.getItem('email');
    const navigate = useNavigate();

    return (
        <>
            <Dropdown icon={
                <Avatar size={34} email={email || ""} />
            }>
                <>
                    <Botao
                        label="Perfil"
                        variant="secondary"
                        width="100%"
                        onClick={() => navigate('/perfil')}
                    />
                    <Botao
                        label="Suporte"
                        variant="secondary"
                        width="100%"
                        onClick={handleSuportPortal}
                    />
                    <Botao
                        label="Sair"
                        variant="secondary"
                        width="100%"
                        onClick={() => {
                            instancia
                                .get(`/logout?code=${process.env.REACT_APP_API_CODE}`)
                                .then(res => navigate('/'))
                        }}
                    />
                </>

            </Dropdown>
        </>
    );
};

export default MenuAvatar;