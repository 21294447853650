import React, { useEffect, useState } from "react";
import FormsLogin from "../../containers/FormsLogin";
import styled from "styled-components";
import { style } from "../../styles";
import backImage from '../../assets/images/login.svg';
import useTheme from "../../state/hooks/useTheme";
import { TTheme } from "../../types/types";
import instancia from "../../instancia";
import { Navigate } from "react-router-dom";
import { handleSuportPortal } from "../../utils";
import Botao from "../../components/Botao";
import { ReactComponent as Icon } from '../../assets/images/suporte-colorido.svg';

const Page = styled.div<{ theme: TTheme }>`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme === 'light' ? style.colors['light']["bg-primary"] : style.colors['dark']["bg-primary"]};
    background-image: url(${backImage});
    background-position: center;
    background-repeat: no-repeat;
`

const BotaoSuporte = styled.div`
    width: 120px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 20px;
`


const PageLogin = () => {
    const { theme } = useTheme();
    const [isAuthenticated, setAuth] = useState(false);

    useEffect(() => {
        instancia
            .get(`/auth?code=${process.env.REACT_APP_API_CODE}`, { withCredentials: true })
            .then((res) => {
                if (res.status !== 200) {
                    setAuth(false)
                } else {
                    setAuth(true);
                }
            })
            .catch(() => setAuth(false));
    }, [])

    return (
        <>
            {
                isAuthenticated ? <Navigate to={'/home'} /> :
                    <Page theme={theme}>
                        <BotaoSuporte>
                            <Botao
                                label="Suporte"
                                icon={<Icon width={20} height={20} />}
                                variant="secondary"
                                width="100%"
                                onClick={handleSuportPortal}
                            />
                        </BotaoSuporte>
                        <FormsLogin />
                    </Page>
            }
        </>
    );
}

export default PageLogin;